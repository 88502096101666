<template>
    <demographics @proceed='next' />
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import { goToQuestionnaire } from './helpers';
import { updateSubject } from '@/Study/helpers';
import Demographics from '@/Study/Pages/Demographics.vue';
import { setHasCorona } from '@/Study/module';

export default defineComponent({
    components: { Demographics },
    computed: {
        study(){
            return this.$store.state.study!;
        },
        user(){
            return this.$store.state.user;
        }
    },
    methods: {
        async next(){
            const { dem } = this.study;
            const { sex } = this.$store.state.user;


            if(dem.button !== 'continue')
                return;

            dem.button = 'loading';

            try{
                const updates = { sex: sex ?? null, age: dem.age ?? null };
                updateSubject(updates);
                await goToQuestionnaire();
                dem.button = 'continue';
            }catch{
                dem.button = 'error';
            }
        },
        setHasCorona({ target }: { target: HTMLInputElement }){
            this.study.dem.button = 'continue';
            setHasCorona(Boolean(parseInt(target.value)))
        },
    }
});
</script>